import { computed } from 'mobx';
import { EndorserBrief } from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    endorsersApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/EndorsersStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type EndorsersFilter = {
    search?: string;
    blackList?: boolean;
};

type EndorserItemDetails = EndorserBrief;
export class EndorsersStoreClass extends BasicStore<
    EndorserItemDetails,
    EndorsersFilter
> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-42-endorser.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-31-edit-borrower.json';

    pager = new Pager();
    api: BasicStoreApi<EndorserItemDetails> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                endorsersApi.findAllEndorsers(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                endorsersApi.getEndorser(id, options),
            );
        },
    };

    filterCriteria: FilterCriteria<EndorsersFilter> = new FilterCriteria({});
    @computed get hasFilter() {
        return Boolean(this.filterCriteria.filter.search);
    }
}

export const EndorsersStore = new EndorsersStoreClass();
