import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { EndorserBrief } from 'src/generated-api-client';

type EndorserListItemProps = { item: EndorserBrief };

export const EndorserListItem = observer(({ item }: EndorserListItemProps) => {
    return (
        <MasterDetailsAsideItem to={EntityLinks.endorsers.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={item?.email}>
                    <EllipsisContainer>
                        <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                        <MainEntityListItemText>
                            {item?.email}
                        </MainEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
            </MainContainer>
        </MasterDetailsAsideItem>
    );
});
