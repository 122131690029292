/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import Utils from 'formiojs/utils';
// import plugins if you need
import React, { useMemo, useRef } from 'react';
import { Form } from 'react-formio';
import { useTranslation } from 'react-i18next';
import { getCustomEventHandler } from 'src/components/FormioForm/FormioCustomEventHandlersMap';
import { getFormioDOMEventHandler } from 'src/components/FormioForm/FormioDOMEventHandlersMap';
import {
    FormioFormProps,
    FormioFormSubmission,
} from 'src/components/FormioForm/FormioFormProps';
import { FormNormalizer } from 'src/components/FormioForm/FormNormalizer';
import { sanitizeConfig } from 'src/components/FormioForm/sanitizeConfig';
import { SubmissionNormalizer } from 'src/components/FormioForm/SubmissionNormalizer';
import { ImageGalleryWrapper } from 'src/components/ImageGalleryWrapper/ImageGalleryWrapper';
import { FormioCustomEvent } from 'src/core/Formio.types';
import { Router } from 'src/core/router/Router';
import { useDropdown } from 'src/hooks/useDropdown';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function FormioForm<T = any>({
    readOnly = false,
    normalizeSubmission,
    form,
    ...props
}: FormioFormProps<T>) {
    const { submission, onSubmit, onCustomEvent, getForm, onChange } = props;

    const { i18n } = useTranslation();

    const formInstance = useRef<any>();
    const containerRef = useRef<any>();

    const searchQueries = Router.getQuerySearchParams();

    const parsedDataFromUrl = UrlHelper.urlSearchToObject(searchQueries);

    const transformedSubmission = {
        ...props,
        submission: {
            ...submission,
            data: {
                ...submission?.data,
                ...parsedDataFromUrl,
                env: SettingsStore.settings?.env,
            },
        },
    };

    useDropdown(containerRef);

    const options = useMemo(() => {
        return {
            readOnly,
            ...sanitizeConfig,
            i18next: i18n,
            language: i18n.language,
            evalContext: {
                utils: Utils, // override utils
            },
        };
    }, [readOnly, i18n, i18n.language]);

    // eslint-disable-next-line sonarjs/prefer-immediate-return, sonarjs/cognitive-complexity
    const renderedForm = useMemo(() => {
        if (!form) {
            return null;
        }

        return (
            <Form
                {...transformedSubmission}
                form={FormNormalizer.normalize(form)}
                ref={(form: any) => {
                    formInstance.current = form;
                    getForm && getForm(form);
                }}
                options={options}
                onSubmit={(submission: FormioFormSubmission) => {
                    const result = { ...submission };

                    result.data = SubmissionNormalizer.normalizeCrud(
                        formInstance.current,
                        result.data,
                    );

                    if (normalizeSubmission) {
                        result.data = result.data
                            ? SubmissionNormalizer.normalize(
                                  formInstance.current,
                                  result.data,
                              )
                            : {};
                    }

                    onSubmit && onSubmit(result);
                }}
                onCustomEvent={(event: FormioCustomEvent<any>) => {
                    const eventCopy = { ...event };
                    const defaultCustomEventHandler = getCustomEventHandler(
                        event.type,
                    );
                    if (onCustomEvent) {
                        eventCopy.data = SubmissionNormalizer.normalizeCrud(
                            formInstance.current,
                            event.data,
                        );
                        onCustomEvent(eventCopy);
                    }
                    if (defaultCustomEventHandler) {
                        defaultCustomEventHandler(event, { form, ...props });
                    }
                }}
                onChange={onChange}
            />
        );
    }, [form, submission, onSubmit, onCustomEvent, options]);

    return (
        <>
            <StyledFormWrapper
                onClick={(e: React.MouseEvent<HTMLHRElement>) => {
                    const customBehavior = getFormioDOMEventHandler(e.type);
                    if (customBehavior) {
                        customBehavior(e);
                    }
                }}
                ref={containerRef}
                key={i18n.language}
            >
                {renderedForm}
            </StyledFormWrapper>
            <ImageGalleryWrapper containerRef={containerRef} />
        </>
    );
}

const StyledFormWrapper = styled.div.attrs((props) => {
    return {
        ...props,
        className: classNames(props.className, 'formio-container'),
    };
})`
    .radio-selected {
        pointer-events: none;
    }

    *:not(.formio-compoenent-form) .alert {
        display: none;
    }

    .builderComponent {
        .alert {
            display: block;
        }
    }
`;
