// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.62.0-dev.20.develop.5c51855
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityAuditTrail
 */
export interface ActivityAuditTrail {
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    activityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    completedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityAuditTrail
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    processBusinessKey?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zipCode: string;
}
/**
 * 
 * @export
 * @interface AddressBorrowerAllowed
 */
export interface AddressBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    apartment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof AddressBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBorrowerAllowed
     */
    zipCode: string;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodInstallment
 */
export interface AmortisationSchedulePeriodInstallment {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    discountRateCoefficient?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    multiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallment
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodInstallmentBorrowerAllowed
 */
export interface AmortisationSchedulePeriodInstallmentBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    discountRateCoefficient?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    multiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodInstallmentBorrowerAllowed
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodMonthly
 */
export interface AmortisationSchedulePeriodMonthly {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthly
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodMonthlyBorrowerAllowed
 */
export interface AmortisationSchedulePeriodMonthlyBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodMonthlyBorrowerAllowed
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodQuarter
 */
export interface AmortisationSchedulePeriodQuarter {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarter
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodQuarterBorrowerAllowed
 */
export interface AmortisationSchedulePeriodQuarterBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    billingDaysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    billingStatementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    dailyAccrual?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    daysInPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    interestInPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    paymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    paymentNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    principalDebtClosingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    principalDebtOpeningBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    principalInPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodQuarterBorrowerAllowed
     */
    totalPaidInterest?: number;
}
/**
 * 
 * @export
 * @interface ApplicationForm
 */
export interface ApplicationForm {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationForm
     */
    furtherInformationForm?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasBalanceSheet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasBankStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasFinancialStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasFurtherInformation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasPnlStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasSupportingDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationForm
     */
    hasUtilityBill?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationFormBorrowerAllowed
 */
export interface ApplicationFormBorrowerAllowed {
    /**
     * 
     * @type {Array<object>}
     * @memberof ApplicationFormBorrowerAllowed
     */
    furtherInformationForm?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasBalanceSheet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasBankStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasFinancialStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasFurtherInformation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasPnlStatement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasSupportingDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormBorrowerAllowed
     */
    hasUtilityBill?: boolean;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface BankAccountBorrowerAllowed
 */
export interface BankAccountBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof BankAccountBorrowerAllowed
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface Borrower
 */
export interface Borrower {
    /**
     * 
     * @type {number}
     * @memberof Borrower
     */
    id?: number;
    /**
     * 
     * @type {BankAccount}
     * @memberof Borrower
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    blockedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    blockedTill?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelation>}
     * @memberof Borrower
     */
    clientRelations?: Array<ClientRelation>;
    /**
     * 
     * @type {number}
     * @memberof Borrower
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Borrower
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    fullName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Borrower
     */
    homeAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Borrower
     */
    id?: number;
    /**
     * 
     * @type {IdDocument}
     * @memberof Borrower
     */
    idDocument?: IdDocument;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    lastName: string;
    /**
     * 
     * @type {Address}
     * @memberof Borrower
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Borrower
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Borrower
     */
    usCitizenship: boolean;
}
/**
 * 
 * @export
 * @interface BorrowerBorrowerAllowed
 */
export interface BorrowerBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof BorrowerBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {BankAccountBorrowerAllowed}
     * @memberof BorrowerBorrowerAllowed
     */
    bankAccount?: BankAccountBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    blockedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    blockedTill?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelationBorrowerAllowed>}
     * @memberof BorrowerBorrowerAllowed
     */
    clientRelations?: Array<ClientRelationBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof BorrowerBorrowerAllowed
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof BorrowerBorrowerAllowed
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    fullName?: string;
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof BorrowerBorrowerAllowed
     */
    homeAddress?: AddressBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowerBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {IdDocumentBorrowerAllowed}
     * @memberof BorrowerBorrowerAllowed
     */
    idDocument?: IdDocumentBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    lastName: string;
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof BorrowerBorrowerAllowed
     */
    mailingAddress?: AddressBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBorrowerAllowed
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BorrowerBorrowerAllowed
     */
    usCitizenship: boolean;
}
/**
 * 
 * @export
 * @interface BorrowerBrief
 */
export interface BorrowerBrief {
    /**
     * 
     * @type {number}
     * @memberof BorrowerBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    blockedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    blockedTill?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelationBrief>}
     * @memberof BorrowerBrief
     */
    clientRelations?: Array<ClientRelationBrief>;
    /**
     * 
     * @type {number}
     * @memberof BorrowerBrief
     */
    currentAge?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowerBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerBrief
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BorrowerBrief
     */
    usCitizenship: boolean;
}
/**
 * 
 * @export
 * @interface BulkRetryIncidentsResponse
 */
export interface BulkRetryIncidentsResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkRetryIncidentsResponse
     */
    attemptToRetryQty?: number;
}
/**
 * 
 * @export
 * @interface CallResult
 */
export interface CallResult {
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    calledAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    nextReminderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    result: string;
}
/**
 * 
 * @export
 * @interface CallResultBrief
 */
export interface CallResultBrief {
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    calledAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    nextReminderDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    result: string;
}
/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    active?: boolean;
    /**
     * 
     * @type {ApplicationForm}
     * @memberof Campaign
     */
    applicationForm?: ApplicationForm;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    disbursementOption: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name?: string;
    /**
     * 
     * @type {CampaignParameters}
     * @memberof Campaign
     */
    parameters?: CampaignParameters;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof Campaign
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignBorrowerAllowed
 */
export interface CampaignBorrowerAllowed {
    /**
     * 
     * @type {ApplicationFormBorrowerAllowed}
     * @memberof CampaignBorrowerAllowed
     */
    applicationForm?: ApplicationFormBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    disbursementOption: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    name?: string;
    /**
     * 
     * @type {object}
     * @memberof CampaignBorrowerAllowed
     */
    parameters?: object;
    /**
     * 
     * @type {number}
     * @memberof CampaignBorrowerAllowed
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBorrowerAllowed
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBorrowerAllowed
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignBrief
 */
export interface CampaignBrief {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBrief
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    calculationType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    collectionStrategy: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    daysOverdue?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    disbursementOption: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    name?: string;
    /**
     * 
     * @type {CampaignParametersBrief}
     * @memberof CampaignBrief
     */
    parameters?: CampaignParametersBrief;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    rangeOfDaysForFirstPaymentDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    scoringModel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBrief
     */
    scoringProviders?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    signingOption?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    templateName: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof CampaignBrief
     */
    termPeriodType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    underwritingDecisionMaking: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    verificationDecisionMaking: string;
}
/**
 * 
 * @export
 * @interface CampaignParameters
 */
export interface CampaignParameters {
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParameters
     */
    minAmount: number;
}
/**
 * 
 * @export
 * @interface CampaignParametersBrief
 */
export interface CampaignParametersBrief {
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignParametersBrief
     */
    minAmount: number;
}
/**
 * 
 * @export
 * @interface ClientRelation
 */
export interface ClientRelation {
    /**
     * 
     * @type {Borrower}
     * @memberof ClientRelation
     */
    borrower?: Borrower;
    /**
     * 
     * @type {Endorser}
     * @memberof ClientRelation
     */
    endorser?: Endorser;
    /**
     * 
     * @type {number}
     * @memberof ClientRelation
     */
    id?: number;
    /**
     * 
     * @type {LoanApplication}
     * @memberof ClientRelation
     */
    loanApplication?: LoanApplication;
    /**
     * 
     * @type {string}
     * @memberof ClientRelation
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface ClientRelationBorrowerAllowed
 */
export interface ClientRelationBorrowerAllowed {
    /**
     * 
     * @type {BorrowerBorrowerAllowed}
     * @memberof ClientRelationBorrowerAllowed
     */
    borrower?: BorrowerBorrowerAllowed;
    /**
     * 
     * @type {EndorserBorrowerAllowed}
     * @memberof ClientRelationBorrowerAllowed
     */
    endorser?: EndorserBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof ClientRelationBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {LoanApplicationBorrowerAllowed}
     * @memberof ClientRelationBorrowerAllowed
     */
    loanApplication?: LoanApplicationBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof ClientRelationBorrowerAllowed
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface ClientRelationBrief
 */
export interface ClientRelationBrief {
    /**
     * 
     * @type {BorrowerBrief}
     * @memberof ClientRelationBrief
     */
    borrower?: BorrowerBrief;
    /**
     * 
     * @type {EndorserBrief}
     * @memberof ClientRelationBrief
     */
    endorser?: EndorserBrief;
    /**
     * 
     * @type {LoanApplicationBrief}
     * @memberof ClientRelationBrief
     */
    loanApplication?: LoanApplicationBrief;
    /**
     * 
     * @type {string}
     * @memberof ClientRelationBrief
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * 
     * @type {Array<CallResult>}
     * @memberof Collection
     */
    callResults?: Array<CallResult>;
    /**
     * 
     * @type {number}
     * @memberof Collection
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof Collection
     */
    loan: Loan;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CollectionBrief
 */
export interface CollectionBrief {
    /**
     * 
     * @type {Array<CallResultBrief>}
     * @memberof CollectionBrief
     */
    callResults?: Array<CallResultBrief>;
    /**
     * 
     * @type {number}
     * @memberof CollectionBrief
     */
    id?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof CollectionBrief
     */
    loan: LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    promiseToPayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    status: string;
}
/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {Address}
     * @memberof ContactPerson
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    cellPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface ContactPersonBorrowerAllowed
 */
export interface ContactPersonBorrowerAllowed {
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof ContactPersonBorrowerAllowed
     */
    address?: AddressBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBorrowerAllowed
     */
    cellPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBorrowerAllowed
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBorrowerAllowed
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBorrowerAllowed
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBorrowerAllowed
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface ContactPersonBrief
 */
export interface ContactPersonBrief {
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBrief
     */
    cellPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBrief
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBrief
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBrief
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonBrief
     */
    relationship?: string;
}
/**
 * 
 * @export
 * @interface DeploymentRepresentation
 */
export interface DeploymentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    deploymentTime: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    tenantId?: string;
}
/**
 * 
 * @export
 * @interface EducationalInstitution
 */
export interface EducationalInstitution {
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitution
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof EducationalInstitution
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitution
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitution
     */
    state: string;
}
/**
 * 
 * @export
 * @interface EducationalInstitutionBorrowerAllowed
 */
export interface EducationalInstitutionBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBorrowerAllowed
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof EducationalInstitutionBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBorrowerAllowed
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBorrowerAllowed
     */
    state: string;
}
/**
 * 
 * @export
 * @interface EducationalInstitutionBrief
 */
export interface EducationalInstitutionBrief {
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBrief
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBrief
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalInstitutionBrief
     */
    state: string;
}
/**
 * 
 * @export
 * @interface EducationalPeriod
 */
export interface EducationalPeriod {
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriod
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriod
     */
    dateTo: string;
    /**
     * 
     * @type {EducationalInstitution}
     * @memberof EducationalPeriod
     */
    educationalInstitution?: EducationalInstitution;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriod
     */
    graduationDate: string;
    /**
     * 
     * @type {number}
     * @memberof EducationalPeriod
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface EducationalPeriodBorrowerAllowed
 */
export interface EducationalPeriodBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBorrowerAllowed
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBorrowerAllowed
     */
    dateTo: string;
    /**
     * 
     * @type {EducationalInstitutionBorrowerAllowed}
     * @memberof EducationalPeriodBorrowerAllowed
     */
    educationalInstitution?: EducationalInstitutionBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBorrowerAllowed
     */
    graduationDate: string;
    /**
     * 
     * @type {number}
     * @memberof EducationalPeriodBorrowerAllowed
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface EducationalPeriodBrief
 */
export interface EducationalPeriodBrief {
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBrief
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBrief
     */
    dateTo: string;
    /**
     * 
     * @type {EducationalInstitutionBrief}
     * @memberof EducationalPeriodBrief
     */
    educationalInstitution?: EducationalInstitutionBrief;
    /**
     * 
     * @type {string}
     * @memberof EducationalPeriodBrief
     */
    graduationDate: string;
}
/**
 * 
 * @export
 * @interface Endorser
 */
export interface Endorser {
    /**
     * 
     * @type {number}
     * @memberof Endorser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelation>}
     * @memberof Endorser
     */
    clientRelations?: Array<ClientRelation>;
    /**
     * 
     * @type {number}
     * @memberof Endorser
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Endorser
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    fullName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Endorser
     */
    homeAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Endorser
     */
    id?: number;
    /**
     * 
     * @type {IdDocument}
     * @memberof Endorser
     */
    idDocument?: IdDocument;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    lastName: string;
    /**
     * 
     * @type {Address}
     * @memberof Endorser
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Endorser
     */
    usCitizenship: boolean;
    /**
     * 
     * @type {string}
     * @memberof Endorser
     */
    workPhoneNumber?: string;
}
/**
 * 
 * @export
 * @interface EndorserBorrowerAllowed
 */
export interface EndorserBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof EndorserBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelationBorrowerAllowed>}
     * @memberof EndorserBorrowerAllowed
     */
    clientRelations?: Array<ClientRelationBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof EndorserBorrowerAllowed
     */
    currentAge?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EndorserBorrowerAllowed
     */
    details?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    fullName?: string;
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof EndorserBorrowerAllowed
     */
    homeAddress?: AddressBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof EndorserBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {IdDocumentBorrowerAllowed}
     * @memberof EndorserBorrowerAllowed
     */
    idDocument?: IdDocumentBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    lastName: string;
    /**
     * 
     * @type {AddressBorrowerAllowed}
     * @memberof EndorserBorrowerAllowed
     */
    mailingAddress?: AddressBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EndorserBorrowerAllowed
     */
    usCitizenship: boolean;
    /**
     * 
     * @type {string}
     * @memberof EndorserBorrowerAllowed
     */
    workPhoneNumber?: string;
}
/**
 * 
 * @export
 * @interface EndorserBrief
 */
export interface EndorserBrief {
    /**
     * 
     * @type {number}
     * @memberof EndorserBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    cellPhoneNumber: string;
    /**
     * 
     * @type {Array<ClientRelationBrief>}
     * @memberof EndorserBrief
     */
    clientRelations?: Array<ClientRelationBrief>;
    /**
     * 
     * @type {number}
     * @memberof EndorserBrief
     */
    currentAge?: number;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    homePhoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof EndorserBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    socialSecurityNumber: string;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    suffix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EndorserBrief
     */
    usCitizenship: boolean;
    /**
     * 
     * @type {string}
     * @memberof EndorserBrief
     */
    workPhoneNumber?: string;
}
/**
 * 
 * @export
 * @interface FileBorrowerAllowed
 */
export interface FileBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof FileBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBorrowerAllowed
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface FileBrief
 */
export interface FileBrief {
    /**
     * 
     * @type {number}
     * @memberof FileBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadResponse
     */
    persistedFileName?: string;
}
/**
 * 
 * @export
 * @interface IdDocument
 */
export interface IdDocument {
    /**
     * 
     * @type {Array<any>}
     * @memberof IdDocument
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof IdDocument
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface IdDocumentBorrowerAllowed
 */
export interface IdDocumentBorrowerAllowed {
    /**
     * 
     * @type {Array<FileBorrowerAllowed>}
     * @memberof IdDocumentBorrowerAllowed
     */
    attachments?: Array<FileBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof IdDocumentBorrowerAllowed
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * The file content to store
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    templated?: boolean;
}
/**
 * 
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * 
     * @type {boolean}
     * @memberof Loan
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof Loan
     */
    actualSnapshot?: LoanSnapshot;
    /**
     * 
     * @type {Borrower}
     * @memberof Loan
     */
    borrower?: Borrower;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplication}
     * @memberof Loan
     */
    loanApplication: LoanApplication;
    /**
     * 
     * @type {LoanProduct}
     * @memberof Loan
     */
    loanProduct?: LoanProduct;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    maturityDate: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanApplication
 */
export interface LoanApplication {
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    active?: boolean;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodInstallment>}
     * @memberof LoanApplication
     */
    amortisationScheduleInstallment?: Array<AmortisationSchedulePeriodInstallment>;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodMonthly>}
     * @memberof LoanApplication
     */
    amortisationScheduleMonthly?: Array<AmortisationSchedulePeriodMonthly>;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodQuarter>}
     * @memberof LoanApplication
     */
    amortisationScheduleQuarter?: Array<AmortisationSchedulePeriodQuarter>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    amountDisbursed?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    approvedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    approvedLoanLimit?: number;
    /**
     * 
     * @type {Borrower}
     * @memberof LoanApplication
     */
    borrower?: Borrower;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanApplication
     */
    campaign?: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    cancelCode?: string;
    /**
     * 
     * @type {ClientRelation}
     * @memberof LoanApplication
     */
    clientRelation?: ClientRelation;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    closedAt?: string;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof LoanApplication
     */
    contactPersons?: Array<ContactPerson>;
    /**
     * 
     * @type {EducationalPeriod}
     * @memberof LoanApplication
     */
    currentEducationalPeriod?: EducationalPeriod;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    customDeclineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    declinedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    educationalGrade: string;
    /**
     * 
     * @type {LoanApplication}
     * @memberof LoanApplication
     */
    endorser?: LoanApplication;
    /**
     * 
     * @type {Array<Endorser>}
     * @memberof LoanApplication
     */
    endorsers?: Array<Endorser>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    financialAssistance?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    floridaTaxRatePct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanApplication
     */
    loan?: Loan;
    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanApplication
     */
    loanProduct?: LoanProduct;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    number?: string;
    /**
     * 
     * @type {EducationalPeriod}
     * @memberof LoanApplication
     */
    plannedEducationalPeriod?: EducationalPeriod;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    proposedMajor: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    proposedOccupation: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    requestedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    status?: string;
    /**
     * 
     * @type {SupportingDocuments}
     * @memberof LoanApplication
     */
    supportingDocuments?: SupportingDocuments;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    totalEducationalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    totalToPay?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationBorrowerAllowed
 */
export interface LoanApplicationBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerAllowed
     */
    active?: boolean;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodInstallmentBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amortisationScheduleInstallment?: Array<AmortisationSchedulePeriodInstallmentBorrowerAllowed>;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodMonthlyBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amortisationScheduleMonthly?: Array<AmortisationSchedulePeriodMonthlyBorrowerAllowed>;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodQuarterBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amortisationScheduleQuarter?: Array<AmortisationSchedulePeriodQuarterBorrowerAllowed>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amountDisbursed?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    approvedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    approvedLoanLimit?: number;
    /**
     * 
     * @type {CampaignBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    campaign?: CampaignBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    cancelCode?: string;
    /**
     * 
     * @type {ClientRelationBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    clientRelation?: ClientRelationBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    closedAt?: string;
    /**
     * 
     * @type {Array<ContactPersonBorrowerAllowed>}
     * @memberof LoanApplicationBorrowerAllowed
     */
    contactPersons?: Array<ContactPersonBorrowerAllowed>;
    /**
     * 
     * @type {EducationalPeriodBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    currentEducationalPeriod?: EducationalPeriodBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    customDeclineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    declinedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    educationalGrade: string;
    /**
     * 
     * @type {LoanApplicationBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    endorser?: LoanApplicationBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    financialAssistance?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    firstPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    floridaTaxRatePct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {LoanBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    loan?: LoanBorrowerAllowed;
    /**
     * 
     * @type {LoanProductBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    loanProduct?: LoanProductBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    number?: string;
    /**
     * 
     * @type {EducationalPeriodBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    plannedEducationalPeriod?: EducationalPeriodBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    proposedMajor: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    proposedOccupation: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    requestedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    status?: string;
    /**
     * 
     * @type {SupportingDocumentsBorrowerAllowed}
     * @memberof LoanApplicationBorrowerAllowed
     */
    supportingDocuments?: SupportingDocumentsBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBorrowerAllowed
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    totalEducationalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBorrowerAllowed
     */
    totalToPay?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationBrief
 */
export interface LoanApplicationBrief {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    amountApproved?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    amountDisbursed?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    amountRequested: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    approvedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    approvedLoanLimit?: number;
    /**
     * 
     * @type {BorrowerBrief}
     * @memberof LoanApplicationBrief
     */
    borrower?: BorrowerBrief;
    /**
     * 
     * @type {CampaignBrief}
     * @memberof LoanApplicationBrief
     */
    campaign?: CampaignBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    cancelCode?: string;
    /**
     * 
     * @type {ClientRelationBrief}
     * @memberof LoanApplicationBrief
     */
    clientRelation?: ClientRelationBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    closedAt?: string;
    /**
     * 
     * @type {Array<ContactPersonBrief>}
     * @memberof LoanApplicationBrief
     */
    contactPersons?: Array<ContactPersonBrief>;
    /**
     * 
     * @type {EducationalPeriodBrief}
     * @memberof LoanApplicationBrief
     */
    currentEducationalPeriod?: EducationalPeriodBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    customDeclineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    declineCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    declined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    declinedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    educationalGrade: string;
    /**
     * 
     * @type {LoanApplicationBrief}
     * @memberof LoanApplicationBrief
     */
    endorser?: LoanApplicationBrief;
    /**
     * 
     * @type {Array<EndorserBrief>}
     * @memberof LoanApplicationBrief
     */
    endorsers?: Array<EndorserBrief>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    financialAssistance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    floridaTaxRatePct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    id?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanApplicationBrief
     */
    loan?: LoanBrief;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanApplicationBrief
     */
    loanProduct?: LoanProductBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    number?: string;
    /**
     * 
     * @type {EducationalPeriodBrief}
     * @memberof LoanApplicationBrief
     */
    plannedEducationalPeriod?: EducationalPeriodBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    proposedMajor: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    proposedOccupation: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    requestedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    status?: string;
    /**
     * 
     * @type {SupportingDocumentsBrief}
     * @memberof LoanApplicationBrief
     */
    supportingDocuments?: SupportingDocumentsBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    terminationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    totalEducationalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    totalToPay?: number;
}
/**
 * 
 * @export
 * @interface LoanBorrowerAllowed
 */
export interface LoanBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof LoanBorrowerAllowed
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshotBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    actualSnapshot?: LoanSnapshotBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplicationBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    loanApplication: LoanApplicationBorrowerAllowed;
    /**
     * 
     * @type {LoanProductBorrowerAllowed}
     * @memberof LoanBorrowerAllowed
     */
    loanProduct?: LoanProductBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    maturityDate: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBorrowerAllowed
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBorrowerAllowed
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanBrief
 */
export interface LoanBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanBrief
     */
    active: boolean;
    /**
     * 
     * @type {LoanSnapshotBrief}
     * @memberof LoanBrief
     */
    actualSnapshot?: LoanSnapshotBrief;
    /**
     * 
     * @type {BorrowerBrief}
     * @memberof LoanBrief
     */
    borrower?: BorrowerBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    issueDate: string;
    /**
     * 
     * @type {LoanApplicationBrief}
     * @memberof LoanBrief
     */
    loanApplication: LoanApplicationBrief;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanBrief
     */
    loanProduct?: LoanProductBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    maturityDate: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    writeOffReason?: string;
}
/**
 * 
 * @export
 * @interface LoanOperation
 */
export interface LoanOperation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    amount: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperation
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperation
     */
    loan: Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperation
     */
    snapshot: LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    type: string;
}
/**
 * 
 * @export
 * @interface LoanOperationRepresentation
 */
export interface LoanOperationRepresentation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    amount: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperationRepresentation
     */
    attachments?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    id?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperationRepresentation
     */
    loan?: Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperationRepresentation
     */
    snapshot: LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    type: string;
}
/**
 * 
 * @export
 * @interface LoanProduct
 */
export interface LoanProduct {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProduct
     */
    active: boolean;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanProduct
     */
    campaign: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    defaultInterestRatePct: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    earlyRepaymentFeeRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    establishmentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    graceInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    gracePeriodDays: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    modifiedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    overduePenalty: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProduct
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    serviceFeeRatePct?: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProduct
     */
    termPeriodType: string;
}
/**
 * 
 * @export
 * @interface LoanProductBorrowerAllowed
 */
export interface LoanProductBorrowerAllowed {
    /**
     * 
     * @type {CampaignBorrowerAllowed}
     * @memberof LoanProductBorrowerAllowed
     */
    campaign: CampaignBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    defaultInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    earlyRepaymentFeeRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    establishmentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    graceInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    gracePeriodDays: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    modifiedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    overduePenalty: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    paymentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBorrowerAllowed
     */
    serviceFeeRatePct?: number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBorrowerAllowed
     */
    termPeriodType: string;
}
/**
 * 
 * @export
 * @interface LoanProductBrief
 */
export interface LoanProductBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProductBrief
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    maxTerm: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minInterestRatePct: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    minTerm: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    modifiedBy?: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBrief
     */
    paymentFrequency?: string;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBrief
     */
    termPeriodType: string;
}
/**
 * 
 * @export
 * @interface LoanSnapshot
 */
export interface LoanSnapshot {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    interestOverdue?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanSnapshot
     */
    loan?: Loan;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotBorrowerAllowed
 */
export interface LoanSnapshotBorrowerAllowed {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    interestOverdue?: number;
    /**
     * 
     * @type {LoanBorrowerAllowed}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    loan?: LoanBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBorrowerAllowed
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotBrief
 */
export interface LoanSnapshotBrief {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    daysInOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    defaultInterestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    earlyRepaymentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    fullRepaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    interestBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    interestOverdue?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanSnapshotBrief
     */
    loan?: LoanBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    outstandingLtvPct?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    overdueAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    overdueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    principalBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    technicalAccount?: number;
}
/**
 * 
 * @export
 * @interface LoanTransaction
 */
export interface LoanTransaction extends TransactionBrief {
    /**
     * 
     * @type {Loan}
     * @memberof LoanTransaction
     */
    loan: Loan;
}


/**
 * 
 * @export
 * @interface LoanTransactionAllOf
 */
export interface LoanTransactionAllOf {
    /**
     * 
     * @type {Loan}
     * @memberof LoanTransactionAllOf
     */
    loan?: Loan;
}
/**
 * 
 * @export
 * @interface LoanTransactionBorrowerAllowed
 */
export interface LoanTransactionBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBorrowerAllowed
     */
    amount: number;
    /**
     * 
     * @type {BankAccountBorrowerAllowed}
     * @memberof LoanTransactionBorrowerAllowed
     */
    bankAccount: BankAccountBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBorrowerAllowed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    initiator?: string;
    /**
     * 
     * @type {LoanBorrowerAllowed}
     * @memberof LoanTransactionBorrowerAllowed
     */
    loan: LoanBorrowerAllowed;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    moneyFlow: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBorrowerAllowed
     */
    status: string;
}
/**
 * 
 * @export
 * @interface LoanTransactionBrief
 */
export interface LoanTransactionBrief {
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBrief
     */
    amount: number;
    /**
     * 
     * @type {object}
     * @memberof LoanTransactionBrief
     */
    bankAccount: object;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    direction: LoanTransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    externalPaymentService?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    initiator?: string;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanTransactionBrief
     */
    loan: LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    moneyFlow: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanTransactionBrief
     */
    processed: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    status: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanTransactionBriefDirectionEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    originalName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface PageActivityAuditTrail
 */
export interface PageActivityAuditTrail {
    /**
     * 
     * @type {Array<ActivityAuditTrail>}
     * @memberof PageActivityAuditTrail
     */
    content?: Array<ActivityAuditTrail>;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageActivityAuditTrail
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageActivityAuditTrail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageBorrowerBrief
 */
export interface PageBorrowerBrief {
    /**
     * 
     * @type {Array<BorrowerBrief>}
     * @memberof PageBorrowerBrief
     */
    content?: Array<BorrowerBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBorrowerBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageBorrowerBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageBorrowerBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBorrowerBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageCampaignBrief
 */
export interface PageCampaignBrief {
    /**
     * 
     * @type {Array<CampaignBrief>}
     * @memberof PageCampaignBrief
     */
    content?: Array<CampaignBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCampaignBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCampaignBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageCollectionBrief
 */
export interface PageCollectionBrief {
    /**
     * 
     * @type {Array<CollectionBrief>}
     * @memberof PageCollectionBrief
     */
    content?: Array<CollectionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCollectionBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCollectionBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageEndorserBrief
 */
export interface PageEndorserBrief {
    /**
     * 
     * @type {Array<EndorserBrief>}
     * @memberof PageEndorserBrief
     */
    content?: Array<EndorserBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEndorserBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEndorserBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEndorserBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEndorserBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEndorserBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageEndorserBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageEndorserBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageEndorserBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageEndorserBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEndorserBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationBorrowerAllowed
 */
export interface PageLoanApplicationBorrowerAllowed {
    /**
     * 
     * @type {Array<LoanApplicationBorrowerAllowed>}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    content?: Array<LoanApplicationBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationBrief
 */
export interface PageLoanApplicationBrief {
    /**
     * 
     * @type {Array<LoanApplicationBrief>}
     * @memberof PageLoanApplicationBrief
     */
    content?: Array<LoanApplicationBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanApplicationBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanApplicationBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanBorrowerAllowed
 */
export interface PageLoanBorrowerAllowed {
    /**
     * 
     * @type {Array<LoanBorrowerAllowed>}
     * @memberof PageLoanBorrowerAllowed
     */
    content?: Array<LoanBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageLoanBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageLoanBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanBrief
 */
export interface PageLoanBrief {
    /**
     * 
     * @type {Array<LoanBrief>}
     * @memberof PageLoanBrief
     */
    content?: Array<LoanBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanOperation
 */
export interface PageLoanOperation {
    /**
     * 
     * @type {Array<LoanOperation>}
     * @memberof PageLoanOperation
     */
    content?: Array<LoanOperation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanOperation
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanOperation
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanProduct
 */
export interface PageLoanProduct {
    /**
     * 
     * @type {Array<LoanProduct>}
     * @memberof PageLoanProduct
     */
    content?: Array<LoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanProduct
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanTransactionBorrowerAllowed
 */
export interface PageLoanTransactionBorrowerAllowed {
    /**
     * 
     * @type {Array<LoanTransactionBorrowerAllowed>}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    content?: Array<LoanTransactionBorrowerAllowed>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBorrowerAllowed}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    pageable?: PageableObjectBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    size?: number;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBorrowerAllowed
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageLoanTransactionBrief
 */
export interface PageLoanTransactionBrief {
    /**
     * 
     * @type {Array<LoanTransactionBrief>}
     * @memberof PageLoanTransactionBrief
     */
    content?: Array<LoanTransactionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanTransactionBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanTransactionBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerBorrower
 */
export interface PageRevisionIntegerBorrower {
    /**
     * 
     * @type {Array<RevisionIntegerBorrower>}
     * @memberof PageRevisionIntegerBorrower
     */
    content?: Array<RevisionIntegerBorrower>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerBorrower
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerBorrower
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerBorrower
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerBorrower
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerLoanProduct
 */
export interface PageRevisionIntegerLoanProduct {
    /**
     * 
     * @type {Array<RevisionIntegerLoanProduct>}
     * @memberof PageRevisionIntegerLoanProduct
     */
    content?: Array<RevisionIntegerLoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerLoanProduct
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerLoanProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageTaskRepresentation
 */
export interface PageTaskRepresentation {
    /**
     * 
     * @type {Array<TaskRepresentation>}
     * @memberof PageTaskRepresentation
     */
    content?: Array<TaskRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTaskRepresentation
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTaskRepresentation
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageTransactionBrief
 */
export interface PageTransactionBrief {
    /**
     * 
     * @type {Array<LoanTransaction>}
     * @memberof PageTransactionBrief
     */
    content?: Array<LoanTransaction>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    numberOfElements?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageTransactionBrief
     */
    pageable?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    size?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageTransactionBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObjectBorrowerAllowed
 */
export interface PageableObjectBorrowerAllowed {
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBorrowerAllowed
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBorrowerAllowed
     */
    paged?: boolean;
    /**
     * 
     * @type {SortBorrowerAllowed}
     * @memberof PageableObjectBorrowerAllowed
     */
    sort?: SortBorrowerAllowed;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBorrowerAllowed
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObjectBrief
 */
export interface PageableObjectBrief {
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    paged?: boolean;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageableObjectBrief
     */
    sort?: SortBrief;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessDefinitionRepresentation
 */
export interface ProcessDefinitionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    deploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    diagram: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionRepresentation
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    historyTimeToLive?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    resource?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    startFormKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    startableInTasklist?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    suspended: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    tenantId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    versionTag?: string;
}
/**
 * 
 * @export
 * @interface RevisionIntegerBorrower
 */
export interface RevisionIntegerBorrower {
    /**
     * 
     * @type {Borrower}
     * @memberof RevisionIntegerBorrower
     */
    entity?: Borrower;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerBorrower
     */
    metadata?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerBorrower
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerBorrower
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerBorrower
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerBorrower
     */
    revisionNumber?: number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerLoanProduct
 */
export interface RevisionIntegerLoanProduct {
    /**
     * 
     * @type {LoanProduct}
     * @memberof RevisionIntegerLoanProduct
     */
    entity?: LoanProduct;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerLoanProduct
     */
    metadata?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    revisionNumber?: number;
}
/**
 * 
 * @export
 * @interface RevisionMetadataInteger
 */
export interface RevisionMetadataInteger {
    /**
     * 
     * @type {object}
     * @memberof RevisionMetadataInteger
     */
    delegate?: object;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    requiredRevisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    requiredRevisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    revisionInstant?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    revisionNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    revisionType?: RevisionMetadataIntegerRevisionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RevisionMetadataIntegerRevisionTypeEnum {
    UNKNOWN = 'UNKNOWN',
    INSERT = 'INSERT',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortBorrowerAllowed
 */
export interface SortBorrowerAllowed {
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBorrowerAllowed
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortBrief
 */
export interface SortBrief {
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SupportingDocuments
 */
export interface SupportingDocuments {
    /**
     * 
     * @type {any}
     * @memberof SupportingDocuments
     */
    lendingDisclosure?: any;
    /**
     * 
     * @type {any}
     * @memberof SupportingDocuments
     */
    loanApplicationForm?: any;
    /**
     * 
     * @type {any}
     * @memberof SupportingDocuments
     */
    selfCertification?: any;
}
/**
 * 
 * @export
 * @interface SupportingDocumentsBorrowerAllowed
 */
export interface SupportingDocumentsBorrowerAllowed {
    /**
     * 
     * @type {FileBorrowerAllowed}
     * @memberof SupportingDocumentsBorrowerAllowed
     */
    lendingDisclosure?: FileBorrowerAllowed;
    /**
     * 
     * @type {FileBorrowerAllowed}
     * @memberof SupportingDocumentsBorrowerAllowed
     */
    loanApplicationForm?: FileBorrowerAllowed;
    /**
     * 
     * @type {FileBorrowerAllowed}
     * @memberof SupportingDocumentsBorrowerAllowed
     */
    selfCertification?: FileBorrowerAllowed;
}
/**
 * 
 * @export
 * @interface SupportingDocumentsBrief
 */
export interface SupportingDocumentsBrief {
    /**
     * 
     * @type {FileBrief}
     * @memberof SupportingDocumentsBrief
     */
    lendingDisclosure?: FileBrief;
    /**
     * 
     * @type {FileBrief}
     * @memberof SupportingDocumentsBrief
     */
    loanApplicationForm?: FileBrief;
    /**
     * 
     * @type {FileBrief}
     * @memberof SupportingDocumentsBrief
     */
    selfCertification?: FileBrief;
}
/**
 * 
 * @export
 * @interface TaskRepresentation
 */
export interface TaskRepresentation {
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    caseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    createTime: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    delegationState?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    deploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    executionId: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskRepresentation
     */
    extensions?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    followUpDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    formKey?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    parentTaskId?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRepresentation
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    processDefinitionId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    processInstanceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskRepresentation
     */
    suspended: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    taskDefinitionKey: string;
    /**
     * 
     * @type {object}
     * @memberof TaskRepresentation
     */
    taskInfo?: object;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    tenantId?: string;
}
/**
 * 
 * @export
 * @interface TemplateDescription
 */
export interface TemplateDescription {
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface TransactionBrief
 */
export interface TransactionBrief {
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    amount: number;
    /**
     * 
     * @type {object}
     * @memberof TransactionBrief
     */
    bankAccount: object;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    direction: TransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    externalPaymentService?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    initiator?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    moneyFlow: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionBrief
     */
    processed: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    type: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionBriefDirectionEnum {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND'
}

/**
 * 
 * @export
 * @interface UiConfigurations
 */
export interface UiConfigurations {
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    appName?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authClientId?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authRealm?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    authServerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    defaultLocale?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    defaultProcessKey?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UiConfigurations
     */
    env?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UiConfigurations
     */
    supportedLocales?: Array<string>;
}
/**
 * 
 * @export
 * @interface UtmParam
 */
export interface UtmParam {
    /**
     * 
     * @type {string}
     * @memberof UtmParam
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UtmParam
     */
    value?: string;
}

/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        env: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/env`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envToMatch: async (toMatch: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'toMatch' is not null or undefined
            if (toMatch === null || toMatch === undefined) {
                throw new RequiredError('toMatch','Required parameter toMatch was null or undefined when calling envToMatch.');
            }
            const localVarPath = `/actuator/env/{toMatch}`
                .replace(`{${"toMatch"}}`, encodeURIComponent(String(toMatch)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/health/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metrics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/metrics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRequiredMetricName: async (requiredMetricName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requiredMetricName' is not null or undefined
            if (requiredMetricName === null || requiredMetricName === undefined) {
                throw new RequiredError('requiredMetricName','Required parameter requiredMetricName was null or undefined when calling metricsRequiredMetricName.');
            }
            const localVarPath = `/actuator/metrics/{requiredMetricName}`
                .replace(`{${"requiredMetricName"}}`, encodeURIComponent(String(requiredMetricName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/prometheus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async env(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).env(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async envToMatch(toMatch: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).envToMatch(toMatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).health(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthPath(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).healthPath(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).info(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async links(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Link; }; }>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).links(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metrics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).metrics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsRequiredMetricName(requiredMetricName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).metricsRequiredMetricName(requiredMetricName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prometheus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ActuatorApiAxiosParamCreator(configuration).prometheus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Actuator web endpoint \'env\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        env(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).env(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'env-toMatch\'
         * @param {string} toMatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        envToMatch(toMatch: string, options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).envToMatch(toMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'health\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).health(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'health-path\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthPath(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).healthPath(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'info\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).info(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator root web endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        links(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Link; }; }> {
            return ActuatorApiFp(configuration).links(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metrics(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).metrics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'metrics-requiredMetricName\'
         * @param {string} requiredMetricName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRequiredMetricName(requiredMetricName: string, options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).metricsRequiredMetricName(requiredMetricName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Actuator web endpoint \'prometheus\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheus(options?: any): AxiosPromise<object> {
            return ActuatorApiFp(configuration).prometheus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * 
     * @summary Actuator web endpoint \'env\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public env(options?: any) {
        return ActuatorApiFp(this.configuration).env(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'env-toMatch\'
     * @param {string} toMatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public envToMatch(toMatch: string, options?: any) {
        return ActuatorApiFp(this.configuration).envToMatch(toMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'health\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public health(options?: any) {
        return ActuatorApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'health-path\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public healthPath(options?: any) {
        return ActuatorApiFp(this.configuration).healthPath(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'info\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public info(options?: any) {
        return ActuatorApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator root web endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public links(options?: any) {
        return ActuatorApiFp(this.configuration).links(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'metrics\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public metrics(options?: any) {
        return ActuatorApiFp(this.configuration).metrics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'metrics-requiredMetricName\'
     * @param {string} requiredMetricName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public metricsRequiredMetricName(requiredMetricName: string, options?: any) {
        return ActuatorApiFp(this.configuration).metricsRequiredMetricName(requiredMetricName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Actuator web endpoint \'prometheus\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public prometheus(options?: any) {
        return ActuatorApiFp(this.configuration).prometheus(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BPMSIncidentsManagementApi - axios parameter creator
 * @export
 */
export const BPMSIncidentsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incident/bulk-retry`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSIncidentsManagementApi - functional programming interface
 * @export
 */
export const BPMSIncidentsManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRetryIncidents(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkRetryIncidentsResponse>> {
            const localVarAxiosArgs = await BPMSIncidentsManagementApiAxiosParamCreator(configuration).bulkRetryIncidents(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BPMSIncidentsManagementApi - factory interface
 * @export
 */
export const BPMSIncidentsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents(options?: any): AxiosPromise<BulkRetryIncidentsResponse> {
            return BPMSIncidentsManagementApiFp(configuration).bulkRetryIncidents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BPMSIncidentsManagementApi - object-oriented interface
 * @export
 * @class BPMSIncidentsManagementApi
 * @extends {BaseAPI}
 */
export class BPMSIncidentsManagementApi extends BaseAPI {
    /**
     * Asynchronously increase retry count for all insidents in the system
     * @summary Bulk retry incidents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSIncidentsManagementApi
     */
    public bulkRetryIncidents(options?: any) {
        return BPMSIncidentsManagementApiFp(this.configuration).bulkRetryIncidents(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BPMSResourcesManagementApi - axios parameter creator
 * @export
 */
export const BPMSResourcesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentName' is not null or undefined
            if (deploymentName === null || deploymentName === undefined) {
                throw new RequiredError('deploymentName','Required parameter deploymentName was null or undefined when calling create.');
            }
            // verify required parameter 'files' is not null or undefined
            if (files === null || files === undefined) {
                throw new RequiredError('files','Required parameter files was null or undefined when calling create.');
            }
            const localVarPath = `/api/deployment/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deploymentName !== undefined) {
                localVarQueryParameter['deployment-name'] = deploymentName;
            }

            if (files) {
                localVarQueryParameter['files'] = files;
            }

            if (migrate !== undefined) {
                localVarQueryParameter['migrate'] = migrate;
            }

            if (retryIncidents !== undefined) {
                localVarQueryParameter['retry-incidents'] = retryIncidents;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm: async (formKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'formKey' is not null or undefined
            if (formKey === null || formKey === undefined) {
                throw new RequiredError('formKey','Required parameter formKey was null or undefined when calling getForm.');
            }
            const localVarPath = `/api/deployment/form`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (formKey !== undefined) {
                localVarQueryParameter['form-key'] = formKey;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/resource/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSResourcesManagementApi - functional programming interface
 * @export
 */
export const BPMSResourcesManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentRepresentation>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).create(deploymentName, files, migrate, retryIncidents, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForm(formKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).getForm(formKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestResource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await BPMSResourcesManagementApiAxiosParamCreator(configuration).getLatestResource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BPMSResourcesManagementApi - factory interface
 * @export
 */
export const BPMSResourcesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<any>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any): AxiosPromise<DeploymentRepresentation> {
            return BPMSResourcesManagementApiFp(configuration).create(deploymentName, files, migrate, retryIncidents, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(formKey: string, options?: any): AxiosPromise<object> {
            return BPMSResourcesManagementApiFp(configuration).getForm(formKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource(options?: any): AxiosPromise<any> {
            return BPMSResourcesManagementApiFp(configuration).getLatestResource(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BPMSResourcesManagementApi - object-oriented interface
 * @export
 * @class BPMSResourcesManagementApi
 * @extends {BaseAPI}
 */
export class BPMSResourcesManagementApi extends BaseAPI {
    /**
     * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
     * @summary Create a deployment with specified resources
     * @param {string} deploymentName Name for the deployment
     * @param {Array<any>} files Resources which the deployment will consist of
     * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
     * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public create(deploymentName: string, files: Array<any>, migrate?: boolean, retryIncidents?: boolean, options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).create(deploymentName, files, migrate, retryIncidents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a form by key from \'/public\' folder in deployment storage
     * @summary Retrieves a deployed form by key
     * @param {string} formKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getForm(formKey: string, options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).getForm(formKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves latest version of the resource by name from \'/public\' folder from deployment
     * @summary Retrieves a public resource by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getLatestResource(options?: any) {
        return BPMSResourcesManagementApiFp(this.configuration).getLatestResource(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BorrowerApi - axios parameter creator
 * @export
 */
export const BorrowerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBorrowers: async (page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrowers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrower: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrower.');
            }
            const localVarPath = `/api/borrowers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerHistory.');
            }
            const localVarPath = `/api/borrowers/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {Borrower} borrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBorrower: async (id: number, borrower: Borrower, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBorrower.');
            }
            // verify required parameter 'borrower' is not null or undefined
            if (borrower === null || borrower === undefined) {
                throw new RequiredError('borrower','Required parameter borrower was null or undefined when calling updateBorrower.');
            }
            const localVarPath = `/api/borrowers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof borrower !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(borrower !== undefined ? borrower : {}) : (borrower || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BorrowerApi - functional programming interface
 * @export
 */
export const BorrowerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBorrowerBrief>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).findAllBorrowers(page, size, sort, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrower(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Borrower>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).getBorrower(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerBorrower>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).getBorrowerHistory(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {Borrower} borrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBorrower(id: number, borrower: Borrower, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Borrower>> {
            const localVarAxiosArgs = await BorrowerApiAxiosParamCreator(configuration).updateBorrower(id, borrower, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BorrowerApi - factory interface
 * @export
 */
export const BorrowerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of borrowers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by borrower\&#39;s full name, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any): AxiosPromise<PageBorrowerBrief> {
            return BorrowerApiFp(configuration).findAllBorrowers(page, size, sort, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single borrower
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrower(id: number, options?: any): AxiosPromise<Borrower> {
            return BorrowerApiFp(configuration).getBorrower(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRevisionIntegerBorrower> {
            return BorrowerApiFp(configuration).getBorrowerHistory(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update borrower
         * @param {number} id 
         * @param {Borrower} borrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBorrower(id: number, borrower: Borrower, options?: any): AxiosPromise<Borrower> {
            return BorrowerApiFp(configuration).updateBorrower(id, borrower, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BorrowerApi - object-oriented interface
 * @export
 * @class BorrowerApi
 * @extends {BaseAPI}
 */
export class BorrowerApi extends BaseAPI {
    /**
     * 
     * @summary Return list of borrowers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [filterSearch] Search by borrower\&#39;s full name, cell phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public findAllBorrowers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any) {
        return BorrowerApiFp(this.configuration).findAllBorrowers(page, size, sort, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single borrower
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public getBorrower(id: number, options?: any) {
        return BorrowerApiFp(this.configuration).getBorrower(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s history
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public getBorrowerHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerApiFp(this.configuration).getBorrowerHistory(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update borrower
     * @param {number} id 
     * @param {Borrower} borrower 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerApi
     */
    public updateBorrower(id: number, borrower: Borrower, options?: any) {
        return BorrowerApiFp(this.configuration).updateBorrower(id, borrower, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BorrowerPortalApi - axios parameter creator
 * @export
 */
export const BorrowerPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactionsByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllTransactionsByLoanId.');
            }
            const localVarPath = `/api/borrower/loan/{id}/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerApplication.');
            }
            const localVarPath = `/api/borrower/loanApplications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplications: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/loanApplications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBorrowerLoan.');
            }
            const localVarPath = `/api/borrower/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoans: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/loans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/borrower/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtmParams: async (utmParam: Array<UtmParam>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'utmParam' is not null or undefined
            if (utmParam === null || utmParam === undefined) {
                throw new RequiredError('utmParam','Required parameter utmParam was null or undefined when calling saveUtmParams.');
            }
            const localVarPath = `/api/borrower/utmParams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof utmParam !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(utmParam !== undefined ? utmParam : {}) : (utmParam || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BorrowerPortalApi - functional programming interface
 * @export
 */
export const BorrowerPortalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanTransactionBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).findAllTransactionsByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplicationBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerApplications(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerLoans(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BorrowerBorrowerAllowed>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).getBorrowerProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUtmParams(utmParam: Array<UtmParam>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BorrowerPortalApiAxiosParamCreator(configuration).saveUtmParams(utmParam, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BorrowerPortalApi - factory interface
 * @export
 */
export const BorrowerPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions for borrower
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanTransactionBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).findAllTransactionsByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplication(id: number, options?: any): AxiosPromise<LoanApplicationBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan applications for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanApplicationBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerApplications(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower\'s loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoan(id: number, options?: any): AxiosPromise<LoanBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerLoan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loans for borrower
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerLoans(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return borrower profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerProfile(options?: any): AxiosPromise<BorrowerBorrowerAllowed> {
            return BorrowerPortalApiFp(configuration).getBorrowerProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save borrower\'s UTM parameters
         * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUtmParams(utmParam: Array<UtmParam>, options?: any): AxiosPromise<void> {
            return BorrowerPortalApiFp(configuration).saveUtmParams(utmParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BorrowerPortalApi - object-oriented interface
 * @export
 * @class BorrowerPortalApi
 * @extends {BaseAPI}
 */
export class BorrowerPortalApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan\'s transactions for borrower
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public findAllTransactionsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).findAllTransactionsByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerApplication(id: number, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan applications for borrower
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerApplications(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerApplications(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower\'s loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerLoan(id: number, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerLoan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loans for borrower
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerLoans(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerLoans(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return borrower profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public getBorrowerProfile(options?: any) {
        return BorrowerPortalApiFp(this.configuration).getBorrowerProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save borrower\'s UTM parameters
     * @param {Array<UtmParam>} utmParam Collection of UTM parameters that should be stored for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowerPortalApi
     */
    public saveUtmParams(utmParam: Array<UtmParam>, options?: any) {
        return BorrowerPortalApiFp(this.configuration).saveUtmParams(utmParam, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling createCampaign.');
            }
            const localVarPath = `/api/campaigns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof campaign !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(campaign !== undefined ? campaign : {}) : (campaign || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns: async (page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterMaxAmount !== undefined) {
                localVarQueryParameter['filter.maxAmount'] = filterMaxAmount;
            }

            if (filterMaxTerm !== undefined) {
                localVarQueryParameter['filter.maxTerm'] = filterMaxTerm;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCampaign.');
            }
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getCampaignByCode.');
            }
            const localVarPath = `/api/campaigns/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (id: number, campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCampaign.');
            }
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling updateCampaign.');
            }
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof campaign !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(campaign !== undefined ? campaign : {}) : (campaign || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).createCampaign(campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCampaignBrief>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaign(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignByCode(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaignByCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(id: number, campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).updateCampaign(id, campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).createCampaign(campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterMaxAmount] Search by campaign max amount
         * @param {number} [filterMaxTerm] Search by campaign max term
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any): AxiosPromise<PageCampaignBrief> {
            return CampaignApiFp(configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(id: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).getCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignByCode(code: string, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).getCampaignByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(id: number, campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).updateCampaign(id, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Create campaign
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public createCampaign(campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).createCampaign(campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaigns
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [filterMaxAmount] Search by campaign max amount
     * @param {number} [filterMaxTerm] Search by campaign max term
     * @param {boolean} [filterActive] Search by campaign active flag
     * @param {string} [filterSearch] Search by campaign name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterMaxAmount?: number, filterMaxTerm?: number, filterActive?: boolean, filterSearch?: string, options?: any) {
        return CampaignApiFp(this.configuration).findAllCampaigns(page, size, sort, filterMaxAmount, filterMaxTerm, filterActive, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(id: number, options?: any) {
        return CampaignApiFp(this.configuration).getCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaignByCode(code: string, options?: any) {
        return CampaignApiFp(this.configuration).getCampaignByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update campaign
     * @param {number} id 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(id: number, campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).updateCampaign(id, campaign, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/collections`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterStartedAtFrom !== undefined) {
                localVarQueryParameter['filter.startedAtFrom'] = filterStartedAtFrom;
            }

            if (filterStartedAtTo !== undefined) {
                localVarQueryParameter['filter.startedAtTo'] = filterStartedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCollection.');
            }
            const localVarPath = `/api/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCollectionBrief>> {
            const localVarAxiosArgs = await CollectionApiAxiosParamCreator(configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollection(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await CollectionApiAxiosParamCreator(configuration).getCollection(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageCollectionBrief> {
            return CollectionApiFp(configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(id: number, options?: any): AxiosPromise<Collection> {
            return CollectionApiFp(configuration).getCollection(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @summary Return list of collections
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by collection statuses
     * @param {string} [filterStartedAtFrom] Search by collection started at from
     * @param {string} [filterStartedAtTo] Search by collection started at to
     * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: any) {
        return CollectionApiFp(this.configuration).findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public getCollection(id: number, options?: any) {
        return CollectionApiFp(this.configuration).getCollection(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DocumentTemplatesApi - axios parameter creator
 * @export
 */
export const DocumentTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/document-templates/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplatesApi - functional programming interface
 * @export
 */
export const DocumentTemplatesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDescription>>> {
            const localVarAxiosArgs = await DocumentTemplatesApiAxiosParamCreator(configuration).findAllTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentTemplatesApi - factory interface
 * @export
 */
export const DocumentTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates(options?: any): AxiosPromise<Array<TemplateDescription>> {
            return DocumentTemplatesApiFp(configuration).findAllTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplatesApi - object-oriented interface
 * @export
 * @class DocumentTemplatesApi
 * @extends {BaseAPI}
 */
export class DocumentTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Return list of available templates in requested folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public findAllTemplates(options?: any) {
        return DocumentTemplatesApiFp(this.configuration).findAllTemplates(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * EndorserApi - axios parameter creator
 * @export
 */
export const EndorserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of endorsers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by endorser\&#39;s social security number, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEndorsers: async (page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/endorsers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single endorser
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndorser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEndorser.');
            }
            const localVarPath = `/api/endorsers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EndorserApi - functional programming interface
 * @export
 */
export const EndorserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of endorsers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by endorser\&#39;s social security number, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEndorsers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEndorserBrief>> {
            const localVarAxiosArgs = await EndorserApiAxiosParamCreator(configuration).findAllEndorsers(page, size, sort, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single endorser
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEndorser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Endorser>> {
            const localVarAxiosArgs = await EndorserApiAxiosParamCreator(configuration).getEndorser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EndorserApi - factory interface
 * @export
 */
export const EndorserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of endorsers
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by endorser\&#39;s social security number, cell phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEndorsers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any): AxiosPromise<PageEndorserBrief> {
            return EndorserApiFp(configuration).findAllEndorsers(page, size, sort, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single endorser
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndorser(id: number, options?: any): AxiosPromise<Endorser> {
            return EndorserApiFp(configuration).getEndorser(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EndorserApi - object-oriented interface
 * @export
 * @class EndorserApi
 * @extends {BaseAPI}
 */
export class EndorserApi extends BaseAPI {
    /**
     * 
     * @summary Return list of endorsers
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [filterSearch] Search by endorser\&#39;s social security number, cell phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndorserApi
     */
    public findAllEndorsers(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, options?: any) {
        return EndorserApiFp(this.configuration).findAllEndorsers(page, size, sort, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single endorser
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndorserApi
     */
    public getEndorser(id: number, options?: any) {
        return EndorserApiFp(this.configuration).getEndorser(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * FileStorageApi - axios parameter creator
 * @export
 */
export const FileStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (form: string, asAttachment?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            if (form === null || form === undefined) {
                throw new RequiredError('form','Required parameter form was null or undefined when calling download.');
            }
            const localVarPath = `/api/file/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (form !== undefined) {
                localVarQueryParameter['form'] = form;
            }

            if (asAttachment !== undefined) {
                localVarQueryParameter['asAttachment'] = asAttachment;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (dir: string, name: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            if (dir === null || dir === undefined) {
                throw new RequiredError('dir','Required parameter dir was null or undefined when calling upload.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling upload.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling upload.');
            }
            const localVarPath = `/api/file/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dir !== undefined) {
                localVarQueryParameter['dir'] = dir;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageApi - functional programming interface
 * @export
 */
export const FileStorageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(form: string, asAttachment?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await FileStorageApiAxiosParamCreator(configuration).download(form, asAttachment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(dir: string, name: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadResponse>> {
            const localVarAxiosArgs = await FileStorageApiAxiosParamCreator(configuration).upload(dir, name, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FileStorageApi - factory interface
 * @export
 */
export const FileStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(form: string, asAttachment?: boolean, options?: any): AxiosPromise<any> {
            return FileStorageApiFp(configuration).download(form, asAttachment, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {any} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(dir: string, name: string, file: any, options?: any): AxiosPromise<FileUploadResponse> {
            return FileStorageApiFp(configuration).upload(dir, name, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStorageApi - object-oriented interface
 * @export
 * @class FileStorageApi
 * @extends {BaseAPI}
 */
export class FileStorageApi extends BaseAPI {
    /**
     * Retrieves a file from configured storage
     * @summary File download
     * @param {string} form The file name to retrieve
     * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public download(form: string, asAttachment?: boolean, options?: any) {
        return FileStorageApiFp(this.configuration).download(form, asAttachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives a file and stores it in configured storage
     * @summary File upload
     * @param {string} dir The directory to store file into
     * @param {string} name The file name
     * @param {any} file The file content to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public upload(dir: string, name: string, file: any, options?: any) {
        return FileStorageApiFp(this.configuration).upload(dir, name, file, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanApi - axios parameter creator
 * @export
 */
export const LoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByBorrowerId.');
            }
            const localVarPath = `/api/loans/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterIssueDateFrom !== undefined) {
                localVarQueryParameter['filter.issueDateFrom'] = filterIssueDateFrom;
            }

            if (filterIssueDateTo !== undefined) {
                localVarQueryParameter['filter.issueDateTo'] = filterIssueDateTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllOperationsByLoanId.');
            }
            const localVarPath = `/api/loans/{id}/operations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findOperationById.');
            }
            const localVarPath = `/api/loans/loan/operations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLoan.');
            }
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApi - functional programming interface
 * @export
 */
export const LoanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllByBorrowerId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanOperation>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findAllOperationsByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOperationById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOperationRepresentation>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).findOperationById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loan>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).getLoan(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApi - factory interface
 * @export
 */
export const LoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanBrief> {
            return LoanApiFp(configuration).findAllByBorrowerId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterIssueDateFrom] Search by loan issue date from
         * @param {string} [filterIssueDateTo] Search by loan issue date to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanBrief> {
            return LoanApiFp(configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanOperation> {
            return LoanApiFp(configuration).findAllOperationsByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById(id: number, options?: any): AxiosPromise<LoanOperationRepresentation> {
            return LoanApiFp(configuration).findOperationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan(id: number, options?: any): AxiosPromise<Loan> {
            return LoanApiFp(configuration).getLoan(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApi - object-oriented interface
 * @export
 * @class LoanApi
 * @extends {BaseAPI}
 */
export class LoanApi extends BaseAPI {
    /**
     * 
     * @summary Return list of borrower\'s loans
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApiFp(this.configuration).findAllByBorrowerId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loans
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan statuses
     * @param {string} [filterIssueDateFrom] Search by loan issue date from
     * @param {string} [filterIssueDateTo] Search by loan issue date to
     * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterIssueDateFrom?: string, filterIssueDateTo?: string, filterSearch?: string, options?: any) {
        return LoanApiFp(this.configuration).findAllLoans(page, size, sort, filterStatuses, filterIssueDateFrom, filterIssueDateTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApiFp(this.configuration).findAllOperationsByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findOperationById(id: number, options?: any) {
        return LoanApiFp(this.configuration).findOperationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public getLoan(id: number, options?: any) {
        return LoanApiFp(this.configuration).getLoan(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanApplicationApi - axios parameter creator
 * @export
 */
export const LoanApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-applications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterRequestedAtFrom !== undefined) {
                localVarQueryParameter['filter.requestedAtFrom'] = filterRequestedAtFrom;
            }

            if (filterRequestedAtTo !== undefined) {
                localVarQueryParameter['filter.requestedAtTo'] = filterRequestedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByBorrowerId1.');
            }
            const localVarPath = `/api/loan-applications/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplication.');
            }
            const localVarPath = `/api/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail: async (id: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAuditTrail.');
            }
            const localVarPath = `/api/loan-applications/{id}/audit-trail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApplicationApi - functional programming interface
 * @export
 */
export const LoanApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBrief>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBrief>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).findAllByBorrowerId1(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplication>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).getApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageActivityAuditTrail>> {
            const localVarAxiosArgs = await LoanApplicationApiAxiosParamCreator(configuration).getAuditTrail(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApplicationApi - factory interface
 * @export
 */
export const LoanApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageLoanApplicationBrief> {
            return LoanApplicationApiFp(configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanApplicationBrief> {
            return LoanApplicationApiFp(configuration).findAllByBorrowerId1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(id: number, options?: any): AxiosPromise<LoanApplication> {
            return LoanApplicationApiFp(configuration).getApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageActivityAuditTrail> {
            return LoanApplicationApiFp(configuration).getAuditTrail(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApplicationApi - object-oriented interface
 * @export
 * @class LoanApplicationApi
 * @extends {BaseAPI}
 */
export class LoanApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan applications
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterStatuses] Search by loan application statuses
     * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
     * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
     * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: any) {
        return LoanApplicationApiFp(this.configuration).findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of borrower\'s loan applications
     * @param {number} id Borrower id
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApplicationApiFp(this.configuration).findAllByBorrowerId1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getApplication(id: number, options?: any) {
        return LoanApplicationApiFp(this.configuration).getApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return audit trail for the loan application
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanApplicationApiFp(this.configuration).getAuditTrail(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanProductApi - axios parameter creator
 * @export
 */
export const LoanProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (loanProduct: LoanProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanProduct' is not null or undefined
            if (loanProduct === null || loanProduct === undefined) {
                throw new RequiredError('loanProduct','Required parameter loanProduct was null or undefined when calling createProduct.');
            }
            const localVarPath = `/api/loan-products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanProduct !== undefined ? loanProduct : {}) : (loanProduct || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByCampaignId.');
            }
            const localVarPath = `/api/loan-products/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts: async (page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterAmount !== undefined) {
                localVarQueryParameter['filter.amount'] = filterAmount;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filter.term'] = filterTerm;
            }

            if (filterCampaignId !== undefined) {
                localVarQueryParameter['filter.campaignId'] = filterCampaignId;
            }

            if (filterPaymentFrequency !== undefined) {
                localVarQueryParameter['filter.paymentFrequency'] = filterPaymentFrequency;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterInterestRate !== undefined) {
                localVarQueryParameter['filter.interestRate'] = filterInterestRate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHistory.');
            }
            const localVarPath = `/api/loan-products/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProduct.');
            }
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: number, loanProduct: LoanProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProduct.');
            }
            // verify required parameter 'loanProduct' is not null or undefined
            if (loanProduct === null || loanProduct === undefined) {
                throw new RequiredError('loanProduct','Required parameter loanProduct was null or undefined when calling updateProduct.');
            }
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanProduct !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanProduct !== undefined ? loanProduct : {}) : (loanProduct || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanProductApi - functional programming interface
 * @export
 */
export const LoanProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(loanProduct: LoanProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).createProduct(loanProduct, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).findAllByCampaignId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerLoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).getHistory(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).getProduct(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: number, loanProduct: LoanProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await LoanProductApiAxiosParamCreator(configuration).updateProduct(id, loanProduct, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanProductApi - factory interface
 * @export
 */
export const LoanProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(loanProduct: LoanProduct, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).createProduct(loanProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanProduct> {
            return LoanProductApiFp(configuration).findAllByCampaignId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any): AxiosPromise<PageLoanProduct> {
            return LoanProductApiFp(configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRevisionIntegerLoanProduct> {
            return LoanProductApiFp(configuration).getHistory(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: number, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).getProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(id: number, loanProduct: LoanProduct, options?: any): AxiosPromise<LoanProduct> {
            return LoanProductApiFp(configuration).updateProduct(id, loanProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanProductApi - object-oriented interface
 * @export
 * @class LoanProductApi
 * @extends {BaseAPI}
 */
export class LoanProductApi extends BaseAPI {
    /**
     * 
     * @summary Create loan product
     * @param {LoanProduct} loanProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public createProduct(loanProduct: LoanProduct, options?: any) {
        return LoanProductApiFp(this.configuration).createProduct(loanProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaign\'s loan products
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanProductApiFp(this.configuration).findAllByCampaignId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan products
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [filterAmount] Search by loan product amount
     * @param {number} [filterTerm] Search by loan product term
     * @param {number} [filterCampaignId] Search by loan campaign id
     * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
     * @param {boolean} [filterActive] Search by loan product active flag
     * @param {string} [filterSearch] Search by loan product code
     * @param {number} [filterInterestRate] Search by loan product interest rate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: any) {
        return LoanProductApiFp(this.configuration).findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return loan product\'s history
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return LoanProductApiFp(this.configuration).getHistory(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan product
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getProduct(id: number, options?: any) {
        return LoanProductApiFp(this.configuration).getProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan product
     * @param {number} id 
     * @param {LoanProduct} loanProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public updateProduct(id: number, loanProduct: LoanProduct, options?: any) {
        return LoanProductApiFp(this.configuration).updateProduct(id, loanProduct, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProcessDefinitionManagerApi - axios parameter creator
 * @export
 */
export const ProcessDefinitionManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet: async (key: string, variables: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling calculateGet.');
            }
            // verify required parameter 'variables' is not null or undefined
            if (variables === null || variables === undefined) {
                throw new RequiredError('variables','Required parameter variables was null or undefined when calling calculateGet.');
            }
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (variables !== undefined) {
                localVarQueryParameter['variables'] = variables;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost: async (key: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling calculatePost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling calculatePost.');
            }
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getProcessDefinition.');
            }
            const localVarPath = `/api/process-definition/key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartForm.');
            }
            const localVarPath = `/api/process-definition/key/{key}/start-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartFormVariables.');
            }
            const localVarPath = `/api/process-definition/key/{key}/form-variables`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getStartFormWithData.');
            }
            const localVarPath = `/api/process-definition/key/{key}/start-form-with-data`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/process-definition`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom: async (key: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling submitStartFrom.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling submitStartFrom.');
            }
            const localVarPath = `/api/process-definition/key/{key}/submit-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessDefinitionManagerApi - functional programming interface
 * @export
 */
export const ProcessDefinitionManagerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGet(key: string, variables: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).calculateGet(key, variables, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).calculatePost(key, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessDefinition(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessDefinitionRepresentation>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getProcessDefinition(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartForm(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartForm(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormVariables(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartFormVariables(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormWithData(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).getStartFormWithData(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStartableByUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcessDefinitionRepresentation>>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).listStartableByUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await ProcessDefinitionManagerApiAxiosParamCreator(configuration).submitStartFrom(key, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessDefinitionManagerApi - factory interface
 * @export
 */
export const ProcessDefinitionManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet(key: string, variables: { [key: string]: object; }, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).calculateGet(key, variables, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).calculatePost(key, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition(key: string, options?: any): AxiosPromise<ProcessDefinitionRepresentation> {
            return ProcessDefinitionManagerApiFp(configuration).getProcessDefinition(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm(key: string, options?: any): AxiosPromise<object> {
            return ProcessDefinitionManagerApiFp(configuration).getStartForm(key, options).then((request) => request(axios, basePath));
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables(key: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return ProcessDefinitionManagerApiFp(configuration).getStartFormVariables(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData(key: string, options?: any): AxiosPromise<object> {
            return ProcessDefinitionManagerApiFp(configuration).getStartFormWithData(key, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser(options?: any): AxiosPromise<Array<ProcessDefinitionRepresentation>> {
            return ProcessDefinitionManagerApiFp(configuration).listStartableByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<TaskRepresentation>> {
            return ProcessDefinitionManagerApiFp(configuration).submitStartFrom(key, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessDefinitionManagerApi - object-oriented interface
 * @export
 * @class ProcessDefinitionManagerApi
 * @extends {BaseAPI}
 */
export class ProcessDefinitionManagerApi extends BaseAPI {
    /**
     * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} variables Input variables
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculateGet(key: string, variables: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculateGet(key, variables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculatePost(key: string, requestBody: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculatePost(key, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getProcessDefinition(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getProcessDefinition(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve start form for the process
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartForm(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartForm(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of start form variables of a latest process definition if allowed to access.
     * @summary Retrieve start form variables
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormVariables(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormVariables(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve start form for the process with form variables included
     * @param {string} key Process definition key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormWithData(key: string, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormWithData(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public listStartableByUser(options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).listStartableByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition by form submission if allowed to access.
     * @summary Start a process instance by form submission
     * @param {string} key Process definition key
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: any) {
        return ProcessDefinitionManagerApiFp(this.configuration).submitStartFrom(key, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TaskManagerApi - axios parameter creator
 * @export
 */
export const TaskManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling claim.');
            }
            const localVarPath = `/api/task/{id}/claim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskForm.');
            }
            const localVarPath = `/api/task/{id}/form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskFormVariables.');
            }
            const localVarPath = `/api/task/{id}/form-variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskFormWithData.');
            }
            const localVarPath = `/api/task/{id}/form-with-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskInfo.');
            }
            const localVarPath = `/api/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/assigned`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/available`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm: async (id: string, requestBody: { [key: string]: object; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitForm.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling submitForm.');
            }
            const localVarPath = `/api/task/{id}/submit-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unclaim.');
            }
            const localVarPath = `/api/task/{id}/unclaim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskManagerApi - functional programming interface
 * @export
 */
export const TaskManagerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claim(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).claim(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskForm(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskForm(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormVariables(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskFormVariables(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormWithData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskFormWithData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).getTaskInfo(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).listAssigned(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).listAvailable(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitForm(id: string, requestBody: { [key: string]: object; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).submitForm(id, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unclaim(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TaskManagerApiAxiosParamCreator(configuration).unclaim(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskManagerApi - factory interface
 * @export
 */
export const TaskManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim(id: string, options?: any): AxiosPromise<void> {
            return TaskManagerApiFp(configuration).claim(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm(id: string, options?: any): AxiosPromise<object> {
            return TaskManagerApiFp(configuration).getTaskForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables(id: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return TaskManagerApiFp(configuration).getTaskFormVariables(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData(id: string, options?: any): AxiosPromise<object> {
            return TaskManagerApiFp(configuration).getTaskFormWithData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(id: string, options?: any): AxiosPromise<TaskRepresentation> {
            return TaskManagerApiFp(configuration).getTaskInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTaskRepresentation> {
            return TaskManagerApiFp(configuration).listAssigned(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTaskRepresentation> {
            return TaskManagerApiFp(configuration).listAvailable(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm(id: string, requestBody: { [key: string]: object; }, options?: any): AxiosPromise<Array<TaskRepresentation>> {
            return TaskManagerApiFp(configuration).submitForm(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim(id: string, options?: any): AxiosPromise<void> {
            return TaskManagerApiFp(configuration).unclaim(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskManagerApi - object-oriented interface
 * @export
 * @class TaskManagerApi
 * @extends {BaseAPI}
 */
export class TaskManagerApi extends BaseAPI {
    /**
     * Assign the task to current user if available.
     * @summary Claim a task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public claim(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).claim(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve form for the task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskForm(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of task form variables of a latest process definition if allowed to access.
     * @summary Retrieve task form variables
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormVariables(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskFormVariables(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve form with data for the task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormWithData(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskFormWithData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve brief info for a single task if available.
     * @summary Get task info
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskInfo(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).getTaskInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List tasks assigned to a user performed the request
     * @summary List assigned tasks
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAssigned(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TaskManagerApiFp(this.configuration).listAssigned(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
     * @summary List tasks available for claim
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAvailable(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TaskManagerApiFp(this.configuration).listAvailable(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete the task by form submission if the user is assignee.
     * @summary Complete a task by form submission
     * @param {string} id Task id
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public submitForm(id: string, requestBody: { [key: string]: object; }, options?: any) {
        return TaskManagerApiFp(this.configuration).submitForm(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unassign the task from current user.
     * @summary Unclaim a task
     * @param {string} id Task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public unclaim(id: string, options?: any) {
        return TaskManagerApiFp(this.configuration).unclaim(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findAllByLoanId.');
            }
            const localVarPath = `/api/transactions/loan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions: async (page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterTypes) {
                localVarQueryParameter['filter.types'] = filterTypes;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterCreatedAtFrom !== undefined) {
                localVarQueryParameter['filter.createdAtFrom'] = filterCreatedAtFrom;
            }

            if (filterCreatedAtTo !== undefined) {
                localVarQueryParameter['filter.createdAtTo'] = filterCreatedAtTo;
            }

            if (filterProcessedAtFrom !== undefined) {
                localVarQueryParameter['filter.processedAtFrom'] = filterProcessedAtFrom;
            }

            if (filterProcessedAtTo !== undefined) {
                localVarQueryParameter['filter.processedAtTo'] = filterProcessedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTransaction.');
            }
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanTransactionBrief>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).findAllByLoanId(id, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransactionBrief>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanTransaction>> {
            const localVarAxiosArgs = await TransactionApiAxiosParamCreator(configuration).getTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLoanTransactionBrief> {
            return TransactionApiFp(configuration).findAllByLoanId(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any): AxiosPromise<PageTransactionBrief> {
            return TransactionApiFp(configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(id: number, options?: any): AxiosPromise<LoanTransaction> {
            return TransactionApiFp(configuration).getTransaction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan\'s transactions
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return TransactionApiFp(this.configuration).findAllByLoanId(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of transactions
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {Array<string>} [filterTypes] Search by transaction types
     * @param {Array<string>} [filterStatuses] Search by transaction statuses
     * @param {string} [filterCreatedAtFrom] Search by transaction created at from
     * @param {string} [filterCreatedAtTo] Search by transaction created at to
     * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
     * @param {string} [filterProcessedAtTo] Search by transaction processed at to
     * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: any) {
        return TransactionApiFp(this.configuration).findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single transaction
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(id: number, options?: any) {
        return TransactionApiFp(this.configuration).getTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UISettingsApi - axios parameter creator
 * @export
 */
export const UISettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("bo-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication borrower-portal required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("borrower-portal", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication integration-client required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("integration-client", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UISettingsApi - functional programming interface
 * @export
 */
export const UISettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UiConfigurations>>> {
            const localVarAxiosArgs = await UISettingsApiAxiosParamCreator(configuration).getSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UISettingsApi - factory interface
 * @export
 */
export const UISettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<Array<UiConfigurations>> {
            return UISettingsApiFp(configuration).getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UISettingsApi - object-oriented interface
 * @export
 * @class UISettingsApi
 * @extends {BaseAPI}
 */
export class UISettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UISettingsApi
     */
    public getSettings(options?: any) {
        return UISettingsApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

}


