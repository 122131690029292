/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formio, Templates, Utils, Widgets } from 'formiojs';
import AllComponents from 'formiojs/components';
import Components from 'formiojs/components/Components';
import { customComponents } from 'hes-formio-components';
import AddressField from 'src/formio/components/Address/Address';
import Container from 'src/formio/components/Container/Container';
import Datagrid from 'src/formio/components/Datagrid/Datagrid';
import Datetime from 'src/formio/components/Datetime/Datetime';
import DropDown from 'src/formio/components/DropDown/DropDown';
import EmailField from 'src/formio/components/EmailField/EmailField';
import File from 'src/formio/components/FileComponent/FileComponent';
import NumberField from 'src/formio/components/NumberField/NumberField';
import PaginationComponent from 'src/formio/components/PaginationComponent/PaginationComponent';
import PhoneField from 'src/formio/components/PhoneField/PhoneField';
import SelectComponent from 'src/formio/components/SelectComponent/SelectComponent';
import TextField from 'src/formio/components/TextField/TextField';
import UrlField from 'src/formio/components/UrlField/UrlField';
import { formioBaseComponentCustomTemplate } from 'src/formio/templates/base';
import { formioDatagridComponentCustomTemplate } from 'src/formio/templates/datagrid';
import { formioDropdownComponentCustomTemplate } from 'src/formio/templates/dropdownComponent';
import { formioFileComponentCustomTemplate } from 'src/formio/templates/file';
import { formioInputComponentCustomTemplate } from 'src/formio/templates/input';
import { formioPaginationComponentCustomTemplate } from 'src/formio/templates/paginationComponent';
import CalendarWidget from 'src/formio/Widgets/CalendarWidget';
import { convertToFormioFileModels } from 'src/utils/formio/convertToFormioFileModels';
import { getCurrentUser } from 'src/utils/formio/getCurrentUser';

// @ts-ignore No other way to redefine calendar
Widgets.calendar = CalendarWidget;

Components.setComponents({
    ...AllComponents,
    ...customComponents,
    ...{
        number: NumberField,
        textfield: TextField,
        datetime: Datetime,
        phoneNumber: PhoneField,
        address: AddressField,
        email: EmailField,
        url: UrlField,
        select: SelectComponent,
        file: File,
        datagrid: Datagrid,
        dropDownComponent: DropDown,
        paginationComponent: PaginationComponent,
        container: Container,
    },
});

Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);

// Extends Formio utils
(Utils as any).getCurrentUser = getCurrentUser;
(Utils as any).convertToFormioFileModels = convertToFormioFileModels;

Templates.current = {
    file: {
        form: formioFileComponentCustomTemplate,
    },
    dropDownTemplate: {
        form: formioDropdownComponentCustomTemplate,
    },
    input: {
        form: formioInputComponentCustomTemplate,
    },
    component: {
        form: formioBaseComponentCustomTemplate,
    },
    datagrid: {
        form: formioDatagridComponentCustomTemplate,
    },
    paginationTemplate: {
        form: formioPaginationComponentCustomTemplate,
    },
};
