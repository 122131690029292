import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { EndorserBrief } from 'src/generated-api-client';
import { EndorserListItem } from 'src/pages/EndorsersPage/EndorserListItem';
import { EndorsersStore } from 'src/stores/EndorsersStore/EndorsersStore';

const store = EndorsersStore;

export const EndorsersPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="endorsers"
            store={store}
            asidePanel={<EndorsersAsidePanel />}
        >
            <EntityPresentation store={store} entityName="endorser" />
        </MasterDetailsPage>
    );
});

const EndorsersAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: EndorserBrief) => {
                return (
                    <List.Item key={item.id}>
                        <EndorserListItem item={item} />
                    </List.Item>
                );
            }}
        />
    );
});
